@import 'Styles/includes';

/**
*
* EntryList
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.EntryList {
    &__Wrapper {
        @include media(sm) {
            flex-wrap: wrap;
            margin-left: -25px;
            display: flex;
            justify-content: center;
        }
    }

    &__Container {
        padding: 15px 0;
    }

    &__Item {
        @include media(sm) {
            display: flex;
            margin-left: 24px;
            width: calc(100% / 3 - 24px);
            flex-grow: 1;
        }
    }
}
