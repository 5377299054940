@import 'Styles/includes';

/**
*
* CardEntry
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.CardEntry {
    $root: &;

    width: 100%;
    display: flex;

    &__Link {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        color: $text;
        text-decoration: none;
        width: 100%;
        border-radius: 4px;
        margin-bottom: 15px;
        padding: 36px 24px 24px 24px;
        background: white;
        transition: box-shadow 0.15s ease;
        box-shadow: 0 0 8px 0 rgba(black, 0.15);

        &:hover {
            box-shadow: 0 0 8px 0 rgba(black, 0.3);

            @media (hover: none) {
                box-shadow: 0 0 8px 0 rgba(black, 0.15);
            }
        }

        @include media(md) {
            margin-bottom: 24px;
            padding: 62px 24px 24px 24px;
        }
    }

    &__Content {
        flex-direction: column;
        display: flex;
        align-items: center;
        text-align: center;
    }

    &__Title {
        font-size: 2rem;
        font-weight: bold;
        margin-bottom: 8px;
        line-height: 1.25;
        text-decoration: none;
    }

    &__Text {
        margin-bottom: 24px;
        font-size: 1.4rem;
        line-height: 1.5;
        letter-spacing: 0.4px;
        max-width: 100%;
    }

    &__Icon {
        font-size: 4.6rem;
        display: block;
        margin-bottom: 24px;
        color: $text;

        &--Red {
            color: $red-icon-color;
        }

        @include media(md) {
            font-size: 4.6rem;
            margin-bottom: 32px;
        }
    }

    &__ArrowText {
        opacity: 0;
        display: none;
        animation: animateCardEntryArrow 250ms ease forwards;

        #{$root}__Link:hover & {
            opacity: 1;
            display: flex;
            align-items: center;
            margin-right: 8px;

            @media (hover: none) {
                opacity: 0;
                display: none;
            }
        }
    }

    &__Arrow {
        position: relative;
        width: 30px;
        height: 30px;

        svg {
            fill: currentColor;
            display: inline-block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 18px;
            height: 18px;
            margin: auto;
        }
    }

    &__ArrowWrapper {
        font-size: 1.3rem;
        font-weight: bold;
        color: $link-color;
        align-items: center;
        display: flex;
        justify-content: center;
    }
}

@keyframes animateCardEntryArrow {
    from {
        opacity: 0;
        transform: translateX(-8px);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}
